.lockBody
{
  display: flex;
  flex-direction: column;
  height: 100%;
}

#footer 
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100px;
  margin: auto 0em 0em;
  padding: 3em 0em;
  background-color: #1b1c1d;
  border-top: 8px solid #A01D21;
  color:rgba(255, 255, 255, 0.9);

  a:link,
  a:visited 
  {
    color: #A01D21;
    text-decoration: none;
  }

  
  a:hover,
  a:active ,
  .site-links a:hover,
  .site-links a:active 
  {
    color: #F47B21;
    text-decoration: none;
    display: inline-block;
  }

  .footer-logo 
  {
    margin-bottom: 1rem;

    img
		{
			width: 35px;
			height: auto;
		}
  }

  .site-links a
  {
    color:rgba(255, 255, 255, 0.9);
    text-decoration: underline;
  }

  .footer-contents 
  {
    display: inline-flex;
    justify-content: center;
    
    > div ~ div
    {
      margin-left: 4px;
      
    }
  }
}

#root
{
  height: 100%;
}

#betalock
{
  background-color: #262728;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button
  {
    border-width: 1px;
    border-radius: 3px;
    text-transform: uppercase;
  }

  input
  {
    border: none;
    border-radius: 3px;
  }

  .modal
  {
    background-color: rgb(124, 124, 124);
    padding: 8px;
    border-radius: 10px;
    text-align: center;
  }

  .logo
  {
    width: 300px;
    margin: -8px;

    img
    {
      width: 100%;
    }
  }
}

#betalock .view {
  // background-image: url('http://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img%20(11).jpg');
  // background-size: cover;
  height: 100vh;
}

#betalock .modal-header {
  margin: auto;
}

#betalock .modal-header img {
  width: 100%;
}

.motd-alert
{
  color: red;
  font-size: smaller;
}

.beta-password-form
{
  margin-bottom: 30px;
}

.beta-password-form .form-group
{
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
}

.beta-password-form i.beta-password-icon
{
  position: relative !important;
  font-size: 1.5rem;
  margin-top: 8px;
  margin-left: 8px;
}

.beta-password-form .invalid-feedback {
  position: absolute;
  top: 35px;
}

#beta-password
{
  margin: 0;
}

input#beta-password
{
  width: 100%;
}